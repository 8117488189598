<template>
  <div class="page-container">
    <div class="header-body">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="add-btn">
              <el-button
                v-permission="['school:school:save']"
                type="primary"
                @click="addData.isOpen = !addData.isOpen"
                >添加</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="schoolList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="schoolName" label="院校"> </el-table-column>
          <el-table-column prop="gmtCreate" label="创建时间"> </el-table-column>
          <el-table-column label="状态">
            <template v-slot="scoped">
              <el-switch
                v-model="scoped.row.disable"
                :active-value="0"
                :inactive-value="1"
                active-text="启用"
                inactive-text="禁用"
                @change="isDisableSchool(scoped.row.disable, scoped.row.id)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template v-slot="scoped">
              <el-button
                type="warning"
                v-permission="['school:school:update']"
                size="small"
                @click="openUpdateDialog(scoped.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                v-permission="['school:school:delete']"
                size="small"
                @click="deleteSchoolHandle(scoped.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <add-school-dialog
      :addData="addData"
      @closeAddDialog="closeAddDialog"
    ></add-school-dialog>
    <update-school-dialog
      :updateData="updateData"
      @closeUpdateDialog="closeUpdateDialog"
    ></update-school-dialog>
  </div>
</template>

<script>
import { getSchoolList, deleteSchool, updateDisable } from '@/api/api.js'
import addSchoolDialog from './dialog/addSchool.vue'
import updateSchoolDialog from './dialog/updateSchool.vue'
import store from '@/store/index'

export default {
  components: {
    addSchoolDialog,
    updateSchoolDialog
  },
  data () {
    return {
      schoolList: [],
      permissionList: [],
      addData: {
        isOpen: false
      },
      updateData: {
        isOpen: false,
        id: ''
      }
    }
  },
  created () {
    this.getSchoolData()
    this.permissionList = store.getters['login/getPermission']
  },
  methods: {
    /* 获取学校列表 */
    getSchoolData () {
      getSchoolList({
        currentPage: 1,
        pageSize: 20
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    /* 打开修改弹窗 */
    openUpdateDialog (id) {
      this.updateData = {
        isOpen: true,
        id: id
      }
    },
    /* 关闭添加弹窗 */
    closeAddDialog (data) {
      this.addData.isOpen = data.isOpen
      if (data.type === 'success') {
        this.getSchoolData()
      }
    },
    /* 关闭更新弹窗 */
    closeUpdateDialog (data) {
      this.updateData.isOpen = data.isOpen
      if (data.type === 'success') {
        this.getSchoolData()
      }
    },
    /* 删除专业 */
    deleteSchoolHandle (id) {
      this.$confirm('此操作将永久删除该院校, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteSchool(id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getSchoolData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /* 启用、禁用学校 */
    isDisableSchool (disable, id) {
      const data = {
        disable,
        id
      }
      updateDisable(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .header-body {
    .add-btn {
      text-align: right;
    }
  }
  .pagination-body {
    .el-pagination {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
